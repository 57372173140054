import React, { useCallback, useEffect, useMemo } from 'react';
import { i18n } from 'locales';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import analytics from '@dvag/dfs-accounting-analytics';
import { DxCard, DxCardContent, DxGrid, DxIcon, DxText } from '@dvag/design-system-react';

import {
  useSplitContracts,
  useTransactions,
  useTransactionType,
  useDisplayGroupList,
  useDeleteSplitContract,
} from 'hooks';
import { Footer } from '@components';
import { Person } from '@store/person';
import { useNavigateFromContract, usePersons } from '@hooks';
import { TransactionTypesEnum } from 'utils';
import { ExpenseTransaction, Transaction, TransactionType } from 'types/transaction';

import { SplitProductColumn } from './SplitProductColumn';
import { edit, split } from './constants';
import SplitProductPageHeader from './SplitProductPageHeader';
import displayGroupOptions from './utils/displayGroupOptions';
import { useCreateSplitContract } from './hooks';
import findDisplayGroup from './utils/findDisplayGroup';
import { filterActiveTransactionTypes, groupTransactionTypes } from '../expenses/helpers';

const findTransactionTypeById = (
  transactionTypes: TransactionType[] | undefined,
  idToFindBy: string | undefined,
): TransactionType | undefined => {
  if (!idToFindBy || !transactionTypes) return {} as TransactionType;
  return transactionTypes.find((transactionType) => transactionType.id === idToFindBy);
};

const formattedContractNumber = (contractNumber: string): string => {
  const decodedContractNumber = decodeURI(contractNumber);
  const matchContractNumber = decodedContractNumber.match(/\d+/);
  if (matchContractNumber) {
    return matchContractNumber[0];
  }
  return decodedContractNumber;
};
const SplitProductPage = () => {
  const { householdId, vertragsnummerMitUnterdepotnummer, productId, dvagContractId } = useParams<{
    householdId: string;
    vertragsnummerMitUnterdepotnummer: string;
    productId: string;
    dvagContractId: number;
  }>();
  const { navigateFromContract } = useNavigateFromContract();
  const { persons } = usePersons();

  const { transactions, isLoadingTransactions } = useTransactions({ householdId });
  const { transactionTypes, isLoadingTransactionTypes } = useTransactionType({
    type: TransactionTypesEnum.Hhb,
    householdId,
  });
  const { displayGroupList: displayGroups, isLoadingDisplayGroupList } = useDisplayGroupList({
    type: 'hhb',
    householdId,
  });
  const getTransactionTypesForValidation = () => {
    const activeTransactionTypes = filterActiveTransactionTypes(transactions, transactionTypes);

    return Object.keys(groupTransactionTypes(activeTransactionTypes));
  };
  const transactionTypesForValidation = getTransactionTypesForValidation();
  const contractNumber = formattedContractNumber(vertragsnummerMitUnterdepotnummer);

  const splittedTransactions = transactions.filter((transaction: Transaction) => {
    const { contractNumber: expenseContractNumber } = transaction as ExpenseTransaction;
    const transactionMetaProductId = transaction.meta?.productId;
    const transactionMetaDisplayInHHB = transaction.meta?.displayInHHB;
    const transactionMetaDvagContractId = transaction.meta?.dvagContractId;
    if (
      parseInt(expenseContractNumber || '', 10) === parseInt(contractNumber, 10) &&
      transactionMetaProductId === parseInt(productId, 10) &&
      !!transactionMetaDisplayInHHB &&
      transactionMetaDvagContractId === +dvagContractId
    ) {
      return transaction;
    }

    return undefined;
  });

  const leftColumnTransaction = splittedTransactions[0];
  const person = persons.find((p) => p.syncId === leftColumnTransaction?.personId) as Person;
  const personId = person?.syncId;

  const {
    createSplitContract,
    isLoading: isCreatingSplit,
    isSuccess: isCreateSplitSuccess,
  } = useCreateSplitContract({
    personId,
    householdId,
  });

  const hasMultipleSplittedTransactions = splittedTransactions.length > 1;
  const splitContractId = String(leftColumnTransaction?.id);
  const { data: splitContracts, isLoading: isSplitContractLoading } = useSplitContracts({
    id: splitContractId,
    personId,
    householdId,
    vertragsnummerMitUnterdepotnummer: contractNumber,
    enabled: hasMultipleSplittedTransactions,
  });

  const { deleteSplitContract, isSuccess: isDeleteSplitSuccess } = useDeleteSplitContract({
    shouldRefetchExpenses: false,
  });

  const transactionTypeLeftColumn = findTransactionTypeById(
    transactionTypes,
    leftColumnTransaction?.transactionTypeId,
  );

  const transactionTypeNameLeftColumn = transactionTypeLeftColumn?.name;
  const transactionTypeIdLeftColumn = leftColumnTransaction?.transactionTypeId;
  const amountAfterTaxesLeftColumn = leftColumnTransaction?.amountAfterTaxes;
  const gesellschaftName = leftColumnTransaction?.meta?.institution;
  const company = leftColumnTransaction?.meta?.productName;
  const displayGroupLeftColumn = findDisplayGroup(displayGroups, transactionTypeLeftColumn);
  const displayGroupIdLeftColumn = displayGroupLeftColumn?.id;

  const rightColumnTransaction = splitContracts[1];
  const transactionTypeIdRightColumn = rightColumnTransaction?.transactionTypeId;
  const transactionTypeRightColumn = findTransactionTypeById(
    transactionTypes,
    transactionTypeIdRightColumn,
  );
  const transactionTypeNameRightColumn = transactionTypeRightColumn?.shortName;
  const amountAfterTaxesRightColumn = rightColumnTransaction?.amountAfterTaxes;
  const totalContractValue = analytics.math.addition(
    amountAfterTaxesLeftColumn || 0,
    amountAfterTaxesRightColumn || 0,
  );

  const matchingTransactionsLength = transactions.filter(
    (transaction) => transaction.transactionTypeId === transactionTypeIdRightColumn,
  ).length;

  const displayGroupRightColumn = findDisplayGroup(displayGroups, transactionTypeRightColumn);
  const displayGroupIdRightColumn = displayGroupRightColumn?.id;

  const defaultValues: FieldValues = useMemo(
    () => ({
      'displayGroupId-edit': displayGroupIdLeftColumn,
      'displayGroupId-split': displayGroupIdRightColumn,
      'amountAfterTaxes-edit': amountAfterTaxesLeftColumn,
      'amountAfterTaxes-split': amountAfterTaxesRightColumn,
      'transactionTypeName-edit': transactionTypeNameLeftColumn,
      'transactionTypeName-split': transactionTypeNameRightColumn,
    }),
    [
      displayGroupIdRightColumn,
      displayGroupIdLeftColumn,
      transactionTypeNameLeftColumn,
      transactionTypeNameRightColumn,
      amountAfterTaxesLeftColumn,
      amountAfterTaxesRightColumn,
    ],
  );
  const { register, reset, formState, setValue, handleSubmit, getValues, watch, trigger } = useForm(
    {
      defaultValues,
      mode: 'onBlur',
    },
  );
  const { errors, isDirty } = formState;

  const navigateToHHBOrCO = useCallback(() => navigateFromContract(), [navigateFromContract]);

  const amountAfterTaxesRightColumnValue = watch('amountAfterTaxes-split') || 0;
  const splitContractData = getValues();

  const handleFormSubmit = async () => {
    if (isDirty) {
      if (
        transactionTypeIdRightColumn &&
        matchingTransactionsLength >= 1 &&
        splittedTransactions[0]
      ) {
        await deleteSplitContract({
          splitContractId: splittedTransactions[0].id as string,
          personId: person.id,
        });
      }
      await createSplitContract({
        id: leftColumnTransaction.id as string,
        contractId: leftColumnTransaction.contractId,
        contractNumber,
        requestData: {
          ...splitContractData,
          transactionTypeIdEdit: transactionTypeIdLeftColumn,
        },
        isContract: true,
      });
    } else {
      navigateToHHBOrCO();
    }
  };

  useEffect(() => {
    if (isCreateSplitSuccess || isDeleteSplitSuccess) {
      navigateToHHBOrCO();
    }
  }, [isCreateSplitSuccess, isDeleteSplitSuccess, navigateToHHBOrCO]);

  const redirectToHHB = async () => {
    if (!isCreatingSplit) {
      await handleSubmit(handleFormSubmit)();
    }
  };

  useEffect(() => {
    const leftColumnCurrencyError = errors['amountAfterTaxes-split'];
    if (amountAfterTaxesRightColumnValue >= 0 && totalContractValue) {
      const difference = !leftColumnCurrencyError
        ? analytics.math.substraction(totalContractValue, amountAfterTaxesRightColumnValue)
        : totalContractValue;
      setValue('amountAfterTaxes-edit', difference, {
        shouldDirty: true,
        shouldValidate: difference > 0,
      });
    }
  }, [amountAfterTaxesRightColumnValue, totalContractValue, errors, setValue, watch]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const mainSplitId = String(splittedTransactions[0]?.id);

  if (
    isLoadingDisplayGroupList ||
    isLoadingTransactions ||
    isLoadingTransactionTypes ||
    !leftColumnTransaction
  ) {
    return null;
  }

  return (
    <form
      className="mx-auto mb-0 mt-10 max-w-[1200px] rounded-md border-0 p-8"
      onSubmit={handleSubmit(handleFormSubmit)}
      key={contractNumber}
      data-testid="split-product-form"
    >
      <DxCard>
        <DxCardContent>
          <SplitProductPageHeader
            product={{
              amount: totalContractValue,
              productType: gesellschaftName as string,
              company: company as string,
            }}
            splitId={mainSplitId}
            isSplitted={splitContracts.length > 1}
            person={person}
            isSplitContractLoading={isSplitContractLoading}
          />
          <DxGrid mq1="12/*" mq2="12/*" mq3="6-6" mq4="6-6" mq5="6-6">
            <SplitProductColumn
              displayGroupSplitOptionList={displayGroupOptions(displayGroups)}
              action={edit}
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
              splittableAmount={totalContractValue}
              transactionTypesForValidation={transactionTypesForValidation}
            />
            <SplitProductColumn
              displayGroupSplitOptionList={displayGroupOptions(displayGroups)}
              action={split}
              register={register}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
              errors={errors}
              splittableAmount={totalContractValue}
              transactionTypesForValidation={transactionTypesForValidation}
            />
          </DxGrid>
          <div className="relative mt-12 flex items-center">
            <DxIcon
              size={24}
              color="gray-83"
              icon="information"
              data-testid="split-product-icon"
              className="absolute"
            />
            <DxText
              data-testid="split-product-info-text"
              className="self-center pl-8"
              type="its"
              color="paragraph"
            >
              {i18n.t('hhb.splitProduct.info')}
            </DxText>
          </div>
        </DxCardContent>
      </DxCard>
      <div className="px-0 py-6 md:m-auto">
        <Footer showFormActionLabels handleContinue={redirectToHHB} />
      </div>
    </form>
  );
};

export default SplitProductPage;
