import React, { lazy } from 'react';

import { Error404 } from 'screens/error';

import { NavigateWithParams, Can } from '@components';
import { ContinuationContextProvider } from 'providers';
import Root from 'Root';
import { queryConfig } from '@lib';

import housingLoader from '@app/basicData/housing/helpers/loadHousing';
import SplitProductPage from 'screens/householdBudget/splitProduct/SplitProductPage';
import ErrorBoundary from './ErrorBoundary';
import { incomeLoader, personLoader } from './dataLoaders';

const SimulationGateway = lazy(() => import('@app/simulationGateway'));
const Contract = lazy(() => import('@app/contract'));
const IncomeLoader = lazy(() => import('screens/incomeLoader'));
const Optimum = lazy(() => import('@app/optimum'));
const ExpensesRoutes = lazy(() => import('screens/householdBudget/ExpensesRoutes'));
const HHBSimulationRoutes = lazy(() => import('screens/householdBudget/simulation'));
const FinancialGradeRoutes = lazy(() => import('screens/financialGrade/routes'));
const LegacyExpensesRoutes = lazy(
  () => import('screens/householdBudget/legacy-expenses/types/LegacyExpensesRoutes'),
);
const Comparison = lazy(() => import('screens/financialGrade/comparison/index'));
const LegacyComparison = lazy(() => import('screens/financialGrade/legacyComparison/index'));
const BasicDataRoutes = lazy(() => import('@app/basicData/routes'));
const SavingsPlanPage = lazy(() => import('@app/savingsPlan'));
const SavingsPlanV2Routes = lazy(() => import('@app/savingsPlanV2/routes'));
const FinancialBalanceSheet = lazy(() => import('screens/financialBalanceSheet'));
const TwoAccountModalRoutes = lazy(() => import('@app/twoAccountModel/routes'));

const simulationRoutes = [
  {
    path: 'simulation/:type/haushalt/:householdId/gateway',
    element: <SimulationGateway />,
  },
  {
    path: 'simulation/:scenarioId/haushalt/:householdId/analyse',
    element: (
      <NavigateWithParams to="/simulation/:scenarioId/haushalt/:householdId/analyse/einkommen" />
    ),
  },
  {
    path: 'simulation/:scenarioId/haushalt/:householdId/analyse',
    element: <NavigateWithParams to="/haushalt/:householdId/analyse/einkommen" />,
  },
  {
    path: 'simulation/:scenarioId/haushalt/:householdId/analyse/einkommen',
    loader: incomeLoader,
    element: <IncomeLoader />,
  },
  {
    path: 'simulation/:scenarioId/haushalt/:householdId/analyse/ffg/finanznote/*',
    element: <FinancialGradeRoutes />,
  },
  {
    path: 'simulation/:scenarioId/haushalt/:householdId/analyse/haushaltsbudget/*',
    element: (
      <Can I="see" this="NSimulation" fallback={<LegacyExpensesRoutes />}>
        <HHBSimulationRoutes />
      </Can>
    ),
  },
  {
    path: 'simulation/:scenarioId/haushalt/:householdId/analyse/vergleich/*',
    element: (
      <Can I="see" this="NSimulation" fallback={<LegacyComparison />}>
        <Comparison />
      </Can>
    ),
  },
  { path: 'simulation/:scenarioId/*', element: <Error404 /> },
];

const analysisRoutes = [
  {
    path: 'haushalt/:householdId/analyse',
    element: <NavigateWithParams to="/haushalt/:householdId/analyse/einkommen" />,
  },
  { path: 'haushalt/:householdId/analyse/einkommen/optimum', element: <Optimum /> },
  {
    path: 'haushalt/:householdId/analyse/haushaltsbudget/*',
    element: (
      <Can I="see" this="NSimulation" fallback={<LegacyExpensesRoutes />}>
        <ExpensesRoutes />
      </Can>
    ),
  },
  {
    path: 'haushalt/:householdId/analyse/haushaltsbudget/vertragsdetails',
    element: <Contract />,
  },
  {
    path: 'haushalt/:householdId/analyse/haushaltsbudget/vertragsdetails-aufteilung/:vertragsnummerMitUnterdepotnummer/:productId/:dvagContractId',
    element: <SplitProductPage />,
  },
  {
    path: 'haushalt/:householdId/analyse/basisdaten/*',
    element: <BasicDataRoutes />,
    id: 'housing',
    loader: housingLoader(queryConfig, personLoader),
  },
  {
    path: 'haushalt/:householdId/analyse/ffg/finanznote/*',
    element: <FinancialGradeRoutes />,
  },
  {
    path: 'haushalt/:householdId/analyse/sparbetrag/*',
    element: (
      <Can I="see" this="SavingsPlanV2" fallback={<SavingsPlanPage />}>
        <SavingsPlanV2Routes />
      </Can>
    ),
  },
  {
    path: 'haushalt/:householdId/analyse/vermoegensbilanz/*',
    element: <FinancialBalanceSheet />,
  },
  {
    path: '/haushalt/:householdId/analyse/zwei-konten-modell/*',
    element: <TwoAccountModalRoutes />,
  },
  {
    path: 'haushalt/:householdId/analyse/vergleich/*',
    element: (
      <Can I="see" this="NSimulation" fallback={<LegacyComparison />}>
        <Comparison />
      </Can>
    ),
  },
  {
    path: 'haushalt/:householdId/analyse/einkommen',
    loader: incomeLoader,
    element: <IncomeLoader />,
  },
  { path: '*', element: <Error404 /> },
];

const createRoutesConfig = () => [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <ContinuationContextProvider>
          <Root />
        </ContinuationContextProvider>
      </ErrorBoundary>
    ),
    id: 'root',
    loader: personLoader(queryConfig),
    children: [...analysisRoutes, ...simulationRoutes],
  },
];

export default createRoutesConfig;
