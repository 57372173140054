import { useCallback } from 'react';

import { generatePath, NavigateOptions, useNavigate } from 'react-router-dom';
import { navigateNext } from '@dvag/dfs-orchestrator-client/messengers';
import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { FlattenObjectKeys } from '@types';
import { appNames, sharedAppRoutes } from '@dvag/dfs-constant-config';
import flattenObject from './helpers/flattenObject';

export const ROUTES = {
  analysis: {
    income: `/haushalt/:householdId/analyse/einkommen`,
    incomeOptimum: `/haushalt/:householdId/analyse/einkommen/optimum`,
    pension: '/haushalt/:householdId/analyse/basisdaten/staatliche-rente/',
    contractDetailsAdd: `/haushalt/:householdId/analyse/haushaltsbudget/vertragsdetails/:category/art/:type/:personId`,
    contractDetailsEdit: `/haushalt/:householdId/analyse/haushaltsbudget/vertragsdetails/art/:type/:contractId/:personId`,

    splitEdit: `/haushalt/:householdId/analyse/haushaltsbudget/vertragsdetails-aufteilung/:vertragsnummerMitUnterdepotnummer/:productId/:dvagContractId`,
    contract: `/haushalt/:householdId/analyse/haushaltsbudget/vertragsdetails`,

    contractOverview: `/haushalt/:householdId/analyse/haushaltsbudget/vertragsuebersicht`,
    contractOverviewAdd: `/haushalt/:householdId/analyse/haushaltsbudget/vertragsuebersicht/hinzufuegen/:category`,
    hhb: `/haushalt/:householdId/analyse/haushaltsbudget`,
    expenses: `/haushalt/:householdId/analyse/haushaltsbudget/ausgaben`,
    financialGradeSlider: `/haushalt/:householdId/analyse/ffg/finanznote/slider`,
    financialGradeStart: `/haushalt/:householdId/analyse/ffg/finanznote/start`,
    comparison: `/haushalt/:householdId/analyse/vergleich`,
    newContract: `/haushalt/:householdId/analyse/haushaltsbudget/vertrag`,
    savingsPlan: `/haushalt/:householdId/analyse/sparbetrag`,
    savingsPlanInvestment: `/haushalt/:householdId/analyse/sparbetrag/investment`,
    housing: `/haushalt/:householdId/analyse/basisdaten/wohnsituation`,
    financialBalanceSheet: `/haushalt/:householdId/analyse/vermoegensbilanz`,
    twoAccountModelExplanation: '/haushalt/:householdId/analyse/zwei-konten-modell/erklaerung',
    twoAccountModelMain: '/haushalt/:householdId/analyse/zwei-konten-modell',
    dashboard: '/haushalt/:householdId/analyse/dashboard',
  },
  simulation: {
    income: `/simulation/:scenarioId/haushalt/:householdId/analyse/einkommen`,
    hhb: `/simulation/:scenarioId/haushalt/:householdId/analyse/haushaltsbudget`,
    expenses: `/simulation/:scenarioId/haushalt/:householdId/analyse/haushaltsbudget/ausgaben`,
    comparison: '/simulation/:scenarioId/haushalt/:householdId/analyse/vergleich/*',
  },
};

type FlattenedRoutesKeys = FlattenObjectKeys<typeof ROUTES>;
type FlattenedSharedAppRoutesKeys = FlattenObjectKeys<typeof sharedAppRoutes>;
type Params = Record<string, unknown>;

const flattenedRoutes = flattenObject(ROUTES) as { [key in FlattenedRoutesKeys]: string };
const flattenedSharedAppRoutes = flattenObject(sharedAppRoutes) as {
  [key in FlattenedSharedAppRoutesKeys]: string;
};

const useNavigation = () => {
  const history = useNavigate();

  const navigateBack = useCallback(() => history(-1), [history]);

  const navigateTo = useCallback(
    (flattenedKey: FlattenedRoutesKeys, params: Params, options?: NavigateOptions | undefined) => {
      const pathTemplate = flattenedRoutes[flattenedKey];
      const path = generatePath(pathTemplate, params);
      history(path, options);
    },
    [history],
  );

  const navigateToMicroFrontend = useCallback(
    (
      appName: keyof typeof appNames,
      flattenedKey: FlattenedSharedAppRoutesKeys,
      params: Params,
    ) => {
      let pathTemplate: FlattenedSharedAppRoutesKeys | string = flattenedKey;
      if (flattenedKey in flattenedSharedAppRoutes) {
        pathTemplate = flattenedSharedAppRoutes[flattenedKey];
      }
      const path = generatePath(pathTemplate, params);
      navigateToMicroApp(appNames[appName], path);
    },
    [],
  );

  const navigateToNextUrlWithExternalFallback = useCallback(
    (
      appName: keyof typeof appNames,
      flattenedKey: FlattenedSharedAppRoutesKeys,
      params: Params,
    ) => {
      let pathTemplate: FlattenedSharedAppRoutesKeys | string = flattenedKey;
      if (flattenedKey in flattenedSharedAppRoutes) {
        pathTemplate = flattenedSharedAppRoutes[flattenedKey];
      }
      const microApp = appNames[appName];
      const path = generatePath(pathTemplate, params);
      const fallback = `/${microApp}${path}`;
      navigateNext(fallback);
    },
    [],
  );
  const navigateToNextUrlWithInternalFallback = useCallback(
    (flattenedKey: FlattenedRoutesKeys, params: Params) => {
      const pathTemplate = flattenedRoutes[flattenedKey];
      const microApp = appNames.accounting;
      const path = generatePath(pathTemplate, params);
      const fallback = `/${microApp}${path}`;
      navigateNext(fallback);
    },
    [],
  );

  return {
    navigateToMicroFrontend,
    navigateTo,
    navigateBack,
    navigateCustom: history,
    navigateToNextUrlWithExternalFallback,
    navigateToNextUrlWithInternalFallback,
  };
};

export default useNavigation;
